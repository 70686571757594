import {Routes} from '@angular/router';
import * as generalPages from './pages';
import * as authPages from './layouts/auth/pages';
import * as pages from './layouts/main-layout/pages';
import * as accountPages from '@app/layouts/account/pages';
import {authGuard, dashboardGuard} from "@app/shared/guards";
import {BlogListComponent} from "@app/layouts/career-blog/blog-list/blog-list.component";
import {ShowBlogComponent} from "@app/layouts/career-blog/show-blog/show-blog.component";
import {CreateBlogComponent} from "@app/layouts/career-blog/create-blog/create-blog.component";
import {ConfirmEmailComponent} from "@app/layouts/main-layout/pages/confirm-email/confirm-email.component";
import CareerBlogComponent from "@app/layouts/career-blog/career-blog.component";

const en_routes: Routes = [
  {
    path: '',
    loadComponent: () => import('@app/layouts/main-layout/main-layout.component'),
    children: [
      {path: '', component: pages.HomePageComponent},
      {path: 'cv-templates', component: pages.CvTemplatesPageComponent},
      {path: 'cover-letters', component: pages.CoverLettersPageComponent},
      {path: 'pricing', component: pages.PricingPageComponent},
      {
        path: 'resume-examples',
        loadComponent: () => import('./layouts/resume-examples/resume-examples.component'),
      },
    ],
  },
  {
    path: 'auth',
    loadComponent: () => import('@app/layouts/auth/auth.component'),
    canActivate: [authGuard],
    children: [
      {path: '', redirectTo: 'sign-in', pathMatch: 'full'},
      {path: 'sign-in', component: authPages.SignInComponent},
      {path: 'sign-up', component: authPages.SignUpComponent},
      {path: 'forgot-password', component: authPages.ForgotPasswordComponent},
      {path: 'reset-password', component: authPages.ResetPasswordComponent},
    ]
  },
  {
    path: 'account',
    loadComponent: () => import('./layouts/account/account.component'),
    canActivate: [dashboardGuard],
    children: [
      {path: '', redirectTo: 'user', pathMatch: 'full'},
      {path: 'user', component: accountPages.UserPageComponent},
      {path: 'security', component: accountPages.ChangePasswordPageComponent},
      {path: 'billing-history', component: accountPages.BillingPageComponent},
      {path: 'choose-plan', component: accountPages.ChoosePlanComponent},
    ]
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./layouts/dashboard/dashboard.component'),
    canActivate: [dashboardGuard],
  },
  {
    path: 'documents/:documentId',
    loadComponent: () => import('./layouts/builder/builder.component'),
    canActivate: [dashboardGuard]
  },
  {path: 'terms-of-use', component: generalPages.TermsOfUseComponent},
  {path: 'refund-policy', component: generalPages.RefundPolicyComponent},
  {path: 'confirm-email', component: ConfirmEmailComponent},
  {path: 'privacy-policy', component: generalPages.PrivacyPolicyComponent},
  {path: 'contact-us', component: generalPages.ContactUsComponent},
  {path: 'about-us', component: generalPages.AboutUsComponent},
  // {
  //   path: 'blog',
  //   loadComponent: () => import('./layouts/career-blog/career-blog.component'),
  //   children: [
  //     {path: '', component: BlogListComponent},
  //     {path: 'create', component: CreateBlogComponent},
  //     {path: ':profession', component: ShowBlogComponent},
  //   ]
  // },
  {
    path: 'blog',
    component: CareerBlogComponent,
    children: [
      {path: '', component: BlogListComponent},
      {path: 'create', component: CreateBlogComponent},
      {path: ':profession', component: ShowBlogComponent},
    ]
  },
  {
    path: 'template-print/3cc7a492-8c98-4133-8b3d-a1bef3f1c9ce/:documentId',
    component: generalPages.TmPrintPageComponent,
  },
];

const ru_routes: Routes = [
  {
    path: 'ru',
    loadComponent: () => import('@app/layouts/main-layout/main-layout.component'),
    children: [
      {path: '', component: pages.HomePageComponent},
      {path: 'shablony-rezyume', component: pages.CvTemplatesPageComponent},
      {path: 'soprovoditelnie-pisma', component: pages.CoverLettersPageComponent},
      {path: 'tseny', component: pages.PricingPageComponent},
    ],
  },
  {
    path: 'ru/avtorizatsiya',
    loadComponent: () => import('@app/layouts/auth/auth.component'),
    canActivate: [authGuard],
    children: [
      {path: '', redirectTo: 'vxod', pathMatch: 'full'},
      {path: 'vxod', component: authPages.SignInComponent},
      {path: 'registratsiya', component: authPages.SignUpComponent},
      {path: 'vosstanovlenie-parolya', component: authPages.ForgotPasswordComponent},
      {path: 'sbros-parolya', component: authPages.ResetPasswordComponent},
    ]
  },
  {
    path: 'ru/akkaunt',
    loadComponent: () => import('./layouts/account/account.component'),
    canActivate: [dashboardGuard],
    children: [
      {path: '', redirectTo: 'polzovatel', pathMatch: 'full'},
      {path: 'polzovatel', component: accountPages.UserPageComponent},
      {path: 'bezopasnost', component: accountPages.ChangePasswordPageComponent},
      {path: 'istoriya-platezhey', component: accountPages.BillingPageComponent},
      {path: 'vybor-plana', component: accountPages.ChoosePlanComponent},
    ]
  },
  {
    path: 'ru/panel-upravleniya',
    loadComponent: () => import('./layouts/dashboard/dashboard.component'),
    canActivate: [dashboardGuard]
  },
  {
    path: 'ru/dokumenti/:documentId',
    loadComponent: () => import('./layouts/builder/builder.component'),
    canActivate: [dashboardGuard]
  },
  {path: 'ru/usloviya-ispolzovaniya', component: generalPages.TermsOfUseComponent},
  {path: 'ru/politika-vozvratov', component: generalPages.RefundPolicyComponent},
  {path: 'ru/confirm-email', component: ConfirmEmailComponent},
  {path: 'ru/politika-konfidencialnosti', component: generalPages.PrivacyPolicyComponent},
  {path: 'ru/kontakty', component: generalPages.ContactUsComponent},
  {path: 'ru/o-nas', component: generalPages.AboutUsComponent},
  {
    path: 'ru/template-print/3cc7a492-8c98-4133-8b3d-a1bef3f1c9ce/:documentId',
    component: generalPages.TmPrintPageComponent
  }
];

const fr_routes: Routes = [
  {
    path: 'fr',
    loadComponent: () => import('@app/layouts/main-layout/main-layout.component'),
    children: [
      {path: '', component: pages.HomePageComponent},
      {path: 'modeles-de-cv', component: pages.CvTemplatesPageComponent}, // 'cv-templates' => 'modeles-de-cv'
      {path: 'lettres-de-motivation', component: pages.CoverLettersPageComponent}, // 'cover-letters' => 'lettres-de-motivation'
      {path: 'tarifs', component: pages.PricingPageComponent}, // 'pricing' => 'tarifs'
    ],
  },
  {
    path: 'fr/authentification', // 'auth' => 'authentification'
    loadComponent: () => import('@app/layouts/auth/auth.component'),
    canActivate: [authGuard],
    children: [
      {path: '', redirectTo: 'connexion', pathMatch: 'full'}, // 'sign-in' => 'connexion'
      {path: 'connexion', component: authPages.SignInComponent}, // 'sign-in' => 'connexion'
      {path: 'inscription', component: authPages.SignUpComponent}, // 'sign-up' => 'inscription'
      {path: 'mot-de-passe-oublie', component: authPages.ForgotPasswordComponent}, // 'forgot-password' => 'mot-de-passe-oublie'
      {path: 'reinitialisation-mot-de-passe', component: authPages.ResetPasswordComponent}, // 'reset-password' => 'reinitialisation-mot-de-passe'
    ]
  },
  {
    path: 'fr/compte', // 'account' => 'compte'
    loadComponent: () => import('./layouts/account/account.component'),
    canActivate: [dashboardGuard],
    children: [
      {path: '', redirectTo: 'utilisateur', pathMatch: 'full'}, // 'user' => 'utilisateur'
      {path: 'utilisateur', component: accountPages.UserPageComponent}, // 'user' => 'utilisateur'
      {path: 'securite', component: accountPages.ChangePasswordPageComponent}, // 'security' => 'securite'
      {path: 'historique-paiement', component: accountPages.BillingPageComponent}, // 'billing-history' => 'historique-paiement'
      {path: 'choisir-un-plan', component: accountPages.ChoosePlanComponent}, // 'choose-plan' => 'choisir-abonnement'
    ]
  },
  {
    path: 'fr/tableau-de-bord', // 'dashboard' => 'tableau-de-bord'
    loadComponent: () => import('./layouts/dashboard/dashboard.component'),
    canActivate: [dashboardGuard],
  },
  {
    path: 'fr/documents/:documentId',
    loadComponent: () => import('./layouts/builder/builder.component'),
    canActivate: [dashboardGuard]
  },
  {path: 'fr/conditions-utilisation', component: generalPages.TermsOfUseComponent}, // 'terms-of-use' => 'conditions-utilisation'
  {path: 'fr/politique-remboursement', component: generalPages.RefundPolicyComponent}, // 'refund-policy' => 'politique-remboursement'
  {path: 'fr/confirmation-email', component: ConfirmEmailComponent}, // 'confirm-email' => 'confirmation-email'
  {path: 'fr/politique-confidentialite', component: generalPages.PrivacyPolicyComponent}, // 'privacy-policy' => 'politique-confidentialite'
  {path: 'fr/nous-contacter', component: generalPages.ContactUsComponent}, // 'contact-us' => 'nous-contacter'
  {path: 'fr/a-propos', component: generalPages.AboutUsComponent}, // 'about-us' => 'a-propos'
  {
    path: 'fr/template-print/3cc7a492-8c98-4133-8b3d-a1bef3f1c9ce/:documentId', // 'template-print' => 'impression-modele'
    component: generalPages.TmPrintPageComponent
  },
];

const de_routes: Routes = [
  {
    path: 'de',
    loadComponent: () => import('@app/layouts/main-layout/main-layout.component'),
    children: [
      {path: '', component: pages.HomePageComponent},
      {path: 'cv-vorlagen', component: pages.CvTemplatesPageComponent},
      {path: 'bewerbungsschreiben', component: pages.CoverLettersPageComponent},
      {path: 'preise', component: pages.PricingPageComponent},
    ],
  },
  {
    path: 'de/auth',
    loadComponent: () => import('@app/layouts/auth/auth.component'),
    canActivate: [authGuard],
    children: [
      {path: '', redirectTo: 'einloggen', pathMatch: 'full'},
      {path: 'einloggen', component: authPages.SignInComponent},
      {path: 'registrieren', component: authPages.SignUpComponent},
      {path: 'passwort-vergessen', component: authPages.ForgotPasswordComponent},
      {path: 'passwort-zuruecksetzen', component: authPages.ResetPasswordComponent},
    ]
  },
  {
    path: 'de/konto',
    loadComponent: () => import('./layouts/account/account.component'),
    canActivate: [dashboardGuard],
    children: [
      {path: '', redirectTo: 'benutzer', pathMatch: 'full'},
      {path: 'benutzer', component: accountPages.UserPageComponent},
      {path: 'sicherheit', component: accountPages.ChangePasswordPageComponent},
      {path: 'zahlungsverlauf', component: accountPages.BillingPageComponent},
      {path: 'abo-waehlen', component: accountPages.ChoosePlanComponent},
    ]
  },
  {
    path: 'de/dashboard',
    loadComponent: () => import('./layouts/dashboard/dashboard.component'),
    canActivate: [dashboardGuard],
  },
  {
    path: 'de/dokumente/:documentId',
    loadComponent: () => import('./layouts/builder/builder.component'),
    canActivate: [dashboardGuard]
  },
  {path: 'de/nutzungsbedingungen', component: generalPages.TermsOfUseComponent},
  {path: 'de/rueckerstattungsrichtlinie', component: generalPages.RefundPolicyComponent},
  {path: 'de/email-bestaetigung', component: ConfirmEmailComponent},
  {path: 'de/datenschutzrichtlinie', component: generalPages.PrivacyPolicyComponent},
  {path: 'de/kontakt', component: generalPages.ContactUsComponent},
  {path: 'de/ueber-uns', component: generalPages.AboutUsComponent},
  {
    path: 'de/template-print/3cc7a492-8c98-4133-8b3d-a1bef3f1c9ce/:documentId',
    component: generalPages.TmPrintPageComponent
  },
];

const es_routes: Routes = [
  {
    path: 'es',
    loadComponent: () => import('@app/layouts/main-layout/main-layout.component'),
    children: [
      {path: '', component: pages.HomePageComponent},
      {path: 'plantillas-cv', component: pages.CvTemplatesPageComponent},
      {path: 'cartas-de-presentacion', component: pages.CoverLettersPageComponent},
      {path: 'precios', component: pages.PricingPageComponent},
    ],
  },
  {
    path: 'es/autenticacion',
    loadComponent: () => import('@app/layouts/auth/auth.component'),
    canActivate: [authGuard],
    children: [
      {path: '', redirectTo: 'iniciar-sesion', pathMatch: 'full'},
      {path: 'iniciar-sesion', component: authPages.SignInComponent},
      {path: 'registrarse', component: authPages.SignUpComponent},
      {path: 'olvido-contraseña', component: authPages.ForgotPasswordComponent},
      {path: 'restablecer-contraseña', component: authPages.ResetPasswordComponent},
    ]
  },
  {
    path: 'es/cuenta',
    loadComponent: () => import('./layouts/account/account.component'),
    canActivate: [dashboardGuard],
    children: [
      {path: '', redirectTo: 'usuario', pathMatch: 'full'},
      {path: 'usuario', component: accountPages.UserPageComponent},
      {path: 'seguridad', component: accountPages.ChangePasswordPageComponent},
      {path: 'historial-facturacion', component: accountPages.BillingPageComponent},
      {path: 'elegir-plan', component: accountPages.ChoosePlanComponent},
    ]
  },
  {
    path: 'es/panel-de-control',
    loadComponent: () => import('./layouts/dashboard/dashboard.component'),
    canActivate: [dashboardGuard],
  },
  {
    path: 'es/documentos/:documentId',
    loadComponent: () => import('./layouts/builder/builder.component'),
    canActivate: [dashboardGuard]
  },
  {path: 'es/terminos-de-uso', component: generalPages.TermsOfUseComponent},
  {path: 'es/politica-de-reembolso', component: generalPages.RefundPolicyComponent},
  {path: 'es/confirmar-correo', component: ConfirmEmailComponent},
  {path: 'es/politica-de-privacidad', component: generalPages.PrivacyPolicyComponent},
  {path: 'es/contacto', component: generalPages.ContactUsComponent},
  {path: 'es/acerca-de-nosotros', component: generalPages.AboutUsComponent},
  {
    path: 'es/template-print/3cc7a492-8c98-4133-8b3d-a1bef3f1c9ce/:documentId',
    component: generalPages.TmPrintPageComponent
  },
];

export const routes: Routes = [
  ...en_routes,
  // ...ru_routes,
  // ...fr_routes,
  // ...de_routes,
  // ...es_routes,

  // {path: '**', component: generalPages.PageNotFoundComponent}
];

console.log('routes', routes);
