import {DOCUMENT} from "@angular/common";
// import Quill, {QuillOptions} from 'quill';
import {BlogService} from "@app/shared/services/blog.service";
import {afterNextRender, Component, inject} from '@angular/core';
import {AppStateService} from "@app/shared/services/app-state.service";

declare const Quill: any;

const sanitizeUrl = (url: string) => {
  const safeUrlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return safeUrlPattern.test(url) ? url : 'about:blank';
}

@Component({
  selector: 'sf-create-blog',
  templateUrl: './create-blog.component.html',
  styleUrl: './create-blog.component.scss',
  imports: []
})
export class CreateBlogComponent {
  private readonly document = inject(DOCUMENT);

  private blogService = inject(BlogService);
  private appStateService = inject(AppStateService);

  constructor() {
    afterNextRender(() => {
      setTimeout(() => {
        if (this.appStateService.isBrowser) {
          this.initQuill();
        }
      });
    });
  }

  private initQuill() {
    this.addFont();
    this.addLint();
    this.addDivider();
    // this.addPanel();

    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'], // text styling
      ['blockquote', 'code-block'], // Block Formats

      ['link', 'image', 'video', 'formula'],

      [{'header': 1}, {'header': 2}, {'header': 3}], // custom button values

      [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}], // List Types

      [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript

      [{'indent': '-1'}, {'indent': '+1'}],          // Indentation

      [{'direction': 'rtl'}],                         // text direction

      [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
      // [{size: ['10px', '12px', '14px', '16px', '18px', '20px', '22px']}],
      [{'header': [1, 2, 3, 4, 5, 6, false]}],

      [{'color': []}, {'background': []}],          // dropdown with defaults from theme
      [{'font': ['poppins', 'serif', 'monospace', 'sans-serif']}],
      [{'align': []}],
      ['divider', /*'panel'*/], // Custom button

      ['clean']  // remove formatting button
    ];

    const options: any = {
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder: 'Start writing your article...',
      theme: 'snow',
    }

    const quill = new Quill('#selfcv-editor', options);

    // Set the default font to 'Poppins'
    const editor = quill.root;
    editor.style.fontFamily = 'Poppins, sans-serif';
    editor.style.fontSize = '16px';

    const toolbar = quill.getModule('toolbar') as any;

    toolbar.addHandler('image', () => {
      const imageUrl = prompt('Enter the image URL');

      if (imageUrl) {
        const range = quill.getSelection();
        quill.insertEmbed(range?.index || 0, 'image', imageUrl, Quill.sources.USER);
      }
    });

    toolbar.addHandler('divider', () => {
      const range = quill.getSelection(true);
      quill.insertEmbed(range.index, 'divider', true, Quill.sources.USER);
    });

    /*toolbar.addHandler('panel', () => {
      const range = quill.getSelection();

      if (range && range.length > 0) {
        const selectedText = quill.getSemanticHTML(range.index, range.length);

        const panelHtml = `<div class="ql-panel">${selectedText}</div>`;

        // Remove the selected text and replace it with the wrapped content
        quill.deleteText(range.index, range.length);

        setTimeout(() => {
          quill.clipboard.dangerouslyPasteHTML(range.index, panelHtml);
        })
      }
    });*/

    setTimeout(() => {
      const placeholderElem = editor.querySelector('pre');
      console.log('placeholderElem', placeholderElem);
    }, 100);
  }

  private addDivider() {
    const BlockEmbed = Quill.import('blots/block/embed') as any;

    class DividerBlot extends BlockEmbed {
      static blotName = 'divider';
      static tagName = 'hr';

      static create() {
        const node = super.create();
        node.setAttribute('class', 'ql-divider');
        return node;
      }
    }

    DividerBlot.blotName = 'divider';
    DividerBlot.tagName = 'hr';

    Quill.register(DividerBlot);

    setTimeout(() => {
      const toolbarElement = this.document.querySelector('.ql-toolbar .ql-divider');
      if (toolbarElement) {
        toolbarElement.innerHTML = '<i class="ph ph-minus fs-18"></i>';
      }
    }, 0);

  }

  private addLint() {
    // Get the default Link blot
    const Link = Quill.import('formats/link') as any;

    // Extend it to customize the behavior
    class CustomLink extends Link {
      static create(value: string) {
        const node = super.create(value);
        node.setAttribute('href', sanitizeUrl(value));
        node.setAttribute('target', '_blank'); // Keep target for security
        node.setAttribute('rel', 'noopener');  // Only keep noopener
        return node;
      }
    }

    Quill.register(CustomLink, true);
  }

  private addFont() {
    // Get the default Link blot
    const Font = Quill.import('formats/font') as any;

    Font.whitelist = ['poppins', 'serif', 'monospace', 'sans-serif'];
    Quill.register(Font, true);
  }

  private addPanel() {
    const Block = Quill.import('blots/block') as any;

    class PanelBlot extends Block {
      static blotName = 'panel';
      static tagName = 'div';

      static create() {
        const node = super.create();

        node.style.border = '1px solid #d9d9d9';
        node.style.padding = '10px';
        node.style.borderRadius = '5px';
        node.style.backgroundColor = '#f9f9f9';
        return node;
      }
    }

    PanelBlot.blotName = 'panel';
    PanelBlot.tagName = 'div';

    Quill.register(PanelBlot);

    setTimeout(() => {
      const toolbarElement = this.document.querySelector('.ql-toolbar .ql-panel');
      if (toolbarElement) {
        toolbarElement.innerHTML = '<i class="ph ph-rectangle-dashed fs-18"></i>';
      }
    }, 0);
  }

}

