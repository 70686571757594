import {map} from "rxjs/operators";
import {ShareButtonDirective} from "ngx-sharebuttons";
import {Meta, Title} from "@angular/platform-browser";
import {updateCanonicalURL} from "@app/shared/helpers";
import {Blog, BlogSeo} from "@app/shared/interfaces/blog";
import {DOCUMENT, NgTemplateOutlet} from "@angular/common";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {BlogService} from "@app/shared/services/blog.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {AppStateService} from "@app/shared/services/app-state.service";
import {Component, DestroyRef, inject, OnInit, Renderer2} from '@angular/core';
import {NzBreadCrumbComponent, NzBreadCrumbItemComponent} from "ng-zorro-antd/breadcrumb";

@Component({
  selector: 'sf-show-blog',
  templateUrl: './show-blog.component.html',
  styleUrl: './show-blog.component.scss',
  imports: [
    NzBreadCrumbComponent,
    NzBreadCrumbItemComponent,
    RouterLink,
    NgTemplateOutlet,
    ShareButtonDirective
  ]
})
export class ShowBlogComponent implements OnInit {
  private readonly metaService = inject(Meta);
  private readonly titleService = inject(Title);
  private readonly document = inject(DOCUMENT);
  private readonly renderer = inject(Renderer2);
  private readonly destroyRef = inject(DestroyRef);
  private readonly activatedRoute = inject(ActivatedRoute);

  private blogService = inject(BlogService);
  private appStateService = inject(AppStateService);

  protected blogObj: Blog | null = null;

  constructor() {
  }

  ngOnInit() {
    this.getBlog();
  }

  private getBlog() {
    this.blogService.getBlog()
      .pipe(
        map((blog) => {
          const profession = this.activatedRoute.snapshot.params['profession'];
          return blog.find((item) => item.content.blogKeyWord === profession);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res) => {
        if (res) {
          this.blogObj = res;
          this.addMetaTags(res.seo);
        }
      });
  }

  private addMetaTags(seo: BlogSeo) {
    const title = seo.title;
    const desc = seo.desc;
    const imgUrl = seo.imgUrl;
    const url = seo.url;
    const keywords = seo.keywords;

    this.titleService.setTitle(title);

    /* Update meta tags */
    this.metaService.updateTag({name: 'description', content: desc});
    this.metaService.updateTag({name: 'keywords', content: keywords});

    /* SEO Scripts Start */

    // const blogLdIfExists = this.document.head.querySelector(`script[type="application/ld+json"][data-key="blog-ld"]`);
    // if (blogLdIfExists) {
    //   this.renderer.removeChild(this.document.head, blogLdIfExists);
    // }
    //
    // const jsonLdScript = this.renderer.createElement('script');
    // this.renderer.setAttribute(jsonLdScript, 'data-key', 'blog-ld');
    // jsonLdScript.text = this.blogJson!.seo.articleLdJson;
    // jsonLdScript.type = 'application/ld+json';
    // this.renderer.appendChild(this.document.head, jsonLdScript);
    //
    // const breadCrampScriptExists = this.document.head.querySelector(`script[type="application/ld+json"][data-key="breadcramp-ld"]`);
    // if (breadCrampScriptExists) {
    //   this.renderer.removeChild(this.document.head, breadCrampScriptExists);
    // }
    //
    // const breadCrampLdJson = this.renderer.createElement('script');
    // this.renderer.setAttribute(breadCrampLdJson, 'data-key', 'breadcramp-ld');
    // breadCrampLdJson.type = 'application/ld+json';
    // breadCrampLdJson.text = this.blogJson!.seo.breadCrampLdJson;
    // this.renderer.appendChild(this.document.head, breadCrampLdJson);

    /* SEO Scripts End */

    /*  Open Graph data  */
    this.metaService.updateTag({property: 'og:title', content: title});
    this.metaService.updateTag({property: 'og:description', content: desc});
    this.metaService.updateTag({property: 'og:url', content: url});
    this.metaService.updateTag({property: 'og:site_name', content: title});
    this.metaService.updateTag({property: 'og:image', content: imgUrl});
    this.metaService.updateTag({property: 'og:image:alt', content: 'image cropper'});

    /*  Twitter Card data  */
    this.metaService.updateTag({name: 'twitter:site', content: url});
    this.metaService.updateTag({name: 'twitter:title', content: title});
    this.metaService.updateTag({name: 'twitter:description', content: desc});
    this.metaService.updateTag({property: 'twitter:image:src', content: imgUrl});

    /* Other */
    this.metaService.updateTag({name: 'msapplication-TileImage', content: imgUrl});

    updateCanonicalURL(url, this.document, this.renderer);
  }

  protected scrollToElementById(id: string) {
    // let offset = 120;
    //
    // let secId = this.blogJson?.section.items
    //   .find((item) => item.title?.id?.replace('-sec', '') === id)?.title?.id;
    //
    // if (id === 'menu-1') {
    //   secId = 'menu-1-sec';
    //   offset = 140;
    // }
    //
    // const element = document.getElementById(secId || '');
    //
    // if (element) {
    //   scrollToElement(element!, offset);
    // }
  }

}
