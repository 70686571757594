import {DOCUMENT} from "@angular/common";
import {RouterOutlet} from "@angular/router";
import {Component, inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FooterComponent, HeaderComponent} from "@app/shared/ui-kits";

@Component({
  selector: 'sf-career-blog',
  templateUrl: './career-blog.component.html',
  styleUrl: './career-blog.component.scss',
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent
  ]
})
export default class CareerBlogComponent implements OnInit, OnDestroy {
  private readonly document = inject(DOCUMENT);
  private readonly renderer = inject(Renderer2);

  constructor() {
  }

  ngOnInit() {
    this.renderer.setStyle(this.document.body, 'background-color', '#f5f5f5');
  }

  ngOnDestroy() {
    this.renderer.removeStyle(this.document.body, 'background-color');
  }

}

